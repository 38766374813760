import { CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppSidebarModule } from '@coreui/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularDataContext, DATA_CONTEXT_CONFIG } from '@themost/angular';
import { APP_LOCATIONS, AuthModule, ConfigurationService, ErrorModule,
  LocalUserStorageService, SharedModule, SIDEBAR_LOCATIONS, UserStorageService } from '@universis/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './layouts/index.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// LOCALES: import extra locales here
import el from '@angular/common/locales/el';
import en from '@angular/common/locales/en';

import { THIS_APP_LOCATIONS } from './app.locations';
import { APP_SIDEBAR_LOCATIONS } from './app.sidebar.locations';
import { AdvancedFormsModule } from '@universis/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

export let options: Partial<any> | (() => Partial<any>);


@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    SharedModule.forRoot(),
    RouterModule,
    AuthModule,
    FormsModule,
    AppRoutingModule,
    AppSidebarModule,
    ErrorModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    AdvancedFormsModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    Title,
    {
        provide: DATA_CONTEXT_CONFIG, useValue: {
            base: '/',
            options: {
                useMediaTypeExtensions: false,
                useResponseConversion: true,
            },
        },
    },
    {
        provide: APP_LOCATIONS,
        useValue: THIS_APP_LOCATIONS,
    },
    {
      provide: SIDEBAR_LOCATIONS,
      useValue: APP_SIDEBAR_LOCATIONS,
    },
    AngularDataContext,
    {
        provide: APP_INITIALIZER,
        // use APP_INITIALIZER to load application configuration
        useFactory: (configurationService: ConfigurationService) =>
            () => {
            // load application configuration
                return configurationService.load().then(() => {
                    // LOCALES: register application locales here
                    registerLocaleData(en);
                    registerLocaleData(el);
                    // return true for APP_INITIALIZER
                    return Promise.resolve(true);
                });
            },
        deps: [ ConfigurationService ],
        multi: true,
    },
    {
        provide: LOCALE_ID,
        useFactory: (configurationService: ConfigurationService) => {
            return configurationService.currentLocale;
        },
        deps: [ConfigurationService],
    },
    // use hash location stategy
    // https://angular.io/api/common/HashLocationStrategy
    {
        provide: LocationStrategy,
        useClass: HashLocationStrategy,
    },
    {
      provide: UserStorageService,
      useClass: LocalUserStorageService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // tslint:disable-next-line: variable-name
  constructor(private _translateService: TranslateService) {
    this.ngOnInit().catch((err) => {
      console.error('An error occurred while init application module.');
      console.error(err);
    });
  }
  // tslint:disable-next-line: use-lifecycle-interface
  private async ngOnInit() {
    // create promises chain
    const sources = environment.languages.map(async (language) => {
      const translations = await import(`../assets/i18n/${language}.json`);
      this._translateService.setTranslation(language, translations, true);
    });
    // execute chain
    await Promise.all(sources);
  }
}
