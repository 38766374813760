// tslint:disable: trailing-comma
export const APP_SIDEBAR_LOCATIONS = [
  {
    name: 'Home',
    key: 'Sidebar.Dashboard',
    url: '/',
    icon: 'fa fa-archive',
    index: 0
  }
];
