<header class="app-header navbar">
  <div class="nav navbar-nav" [ngClass]="applicationImage?'h-100':''">
    <button class="navbar-toggler d-md-none" type="button" appMobileSidebarToggler>
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="nav-item nav-link sis-brand px-3 d-block d-sm-none h-100" href="#"><img src="{{applicationImage}}"
                                                                                      class="sis-brand-logo mr-2 h-100" *ngIf="applicationImage" /><span class="sis-brand-bold">SIS</span></a>
    <a class="nav-item nav-link sis-brand pl-2 d-none d-sm-block h-100" href="#">
      <img src="{{applicationImage}}" class="sis-brand-logo mr-2 h-100" *ngIf="applicationImage" />
      <span class="h-100 align-middle">{{applicationTitle}}</span>
    </a>
  </div>

  <ul class="nav navbar-nav d-sm-down-none ml-auto">
    <!--Language switch dropdown menu-->
    <ng-container *ngIf="languages && languages.length > 1" >
      <li *ngIf="currentLang" class="nav-item dropdown d-none d-md-block" dropdown placement="bottom right">
      <a class="nav-link" href="javascript:void(0)" role="button" aria-haspopup="true"
         aria-expanded="false" dropdownToggle>
        {{currentLang}}<i class="ml-2 fa fa-angle-down"></i>
      </a>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <li role="menuitem" *ngFor="let language of languages">
          <button class="dropdown-item" [disabled]="language===currentLang" type="button"
                  (click)="changeLanguage(language)">{{ "Languages." + language | translate:{id: language} }}</button>
        </li>
      </ul>
    </li>
    </ng-container>
  </ul>
</header>

<div class="app-body">
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid pt-4">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>

<footer class="app-footer">
  <img src="{{applicationFooter}}" class="mr-auto mw-100" *ngIf="applicationFooter" />
  &copy; 2020 Universis Project
  <span class="float-right"></span>
</footer>
