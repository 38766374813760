// tslint:disable: trailing-comma
export const THIS_APP_LOCATIONS = [
  {
    privilege: 'Location',
    target: {
        url: '^/error'
    },
    mask: 1
  },
  {
    privilege: 'Location',
    target: {
        url: '^/'
    },
    mask: 1
  }
];
